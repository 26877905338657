import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import './css/LikeDislike.css';

const LikeDislike = () => {
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);

  const handleLike = () => {
    setLikes(likes + 1);
  };

  const handleDislike = () => {
    setDislikes(dislikes + 1);
  };

  return (
    <div className="like-dislike-container">
      <div className="like-container">
        <FontAwesomeIcon icon={faThumbsUp} onClick={handleLike} className="like-button" />
        <span>{likes}</span>
      </div>
      <div className="dislike-container">
        <FontAwesomeIcon icon={faThumbsDown} onClick={handleDislike} className="dislike-button" />
        <span>{dislikes}</span>
      </div>
    </div>
  );
};

export default LikeDislike;
