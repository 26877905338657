import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LinkContainer } from 'react-router-bootstrap';

function BootstrapNavbar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link>
              <img
                src="/logo.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="Geoverse Logo"
              />
              </Nav.Link>
             </LinkContainer>
          <NavDropdown title="Adventurecraft" id="basic-nav-dropdown">
            <LinkContainer to="/adventurecraft">
              <NavDropdown.Item>Adventurecraft</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to="/adventurecraft/scripts">
              <NavDropdown.Item>Scripts</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/adventurecraft/guides">
              <NavDropdown.Item>Guides</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/adventurecraft/2d_resources">
              <NavDropdown.Item>2D resources</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/adventurecraft/3d_resources">
              <NavDropdown.Item>3D resources</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/adventurecraft/converter">
              <NavDropdown.Item>Blockbench to AC Converter</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
          <LinkContainer to="/about">
            <Nav.Link>About</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default BootstrapNavbar;
