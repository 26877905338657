
import React, { useEffect } from 'react' ;
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './css/App.css';
import HelloWorld from './HelloWorld';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import BootstrapNavbar from './Navbar';
import About from './About'; 
import Adventurecraft from './Adventurecraft'; 
import Scripts from './Scripts';
import Guides from './Guides';
import Resources2D from './Resources2D';
import Resources3D from './Resources3D';
import Converter from './Converter';



function App() {

  return (
    <Router>
      <div className="App">
        <BootstrapNavbar />
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/adventurecraft" element={<Adventurecraft />} />
            <Route path="/adventurecraft/scripts" element={<Scripts />} />
            <Route path="/adventurecraft/guides" element={<Guides />} />
            <Route path="/adventurecraft/2dresources" element={<Resources2D />} />
            <Route path="/adventurecraft/3dresources" element={<Resources3D />} />
            <Route path="/adventurecraft/converter" element={<Converter />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

function Home() {
  useEffect(() => {
    document.title = 'Home - Geoverse';
  }, []);
  return (
    <>
    <h1>Geoverse</h1>
    <HelloWorld />
    <img src="/welcomeLion.webp" alt="Welcome-logo" /> {/* Blue Lion */}
    <MyButton />
  </>
  );
}

function MyButton() {
  return (
    <button>
      ominöser Knopf
    </button>
  );
}


export default App;
