// src/Guides.js
import React, { useEffect } from 'react';

function Guides() {
  useEffect(() => {
    document.title = 'AC Guides - Geoverse';
  }, []);
  return (
    <div>
      <h1>Guides</h1>
      <p></p>
    </div>
  );
}

export default Guides;
