import React from 'react';

const UploadButton = ({ onFileUpload }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.js')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onFileUpload(e.target.result, file.name);
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a valid JavaScript file.');
    }
  };

  return (
    <div>
      <input type="file" accept=".js" onChange={handleFileChange} />
    </div>
  );
};

export default UploadButton;
