import React, { useEffect } from 'react';
import './css/About.css'; // Import the specific styles for the About page

function About() {
  useEffect(() => {
    document.title = 'About - Geoverse';
  }, []);

  return (
    <>
      <div className="apply-background2"></div> {/* Background div */}
      <div className="about-container">
        <h1>About Geoverse</h1>
        <p><span className="sentence-start">G</span>eoverse focuses on <span className="game-name"> <a href="https://adventurecraft.dev" target="_blank" rel="noopener noreferrer">Adventurecraft</a>
  </span>, a Minecraft-like sandbox engine that allows players to craft their own adventures.</p>
        <p><span className="sentence-start">E</span>xplore the resources and consider to also contribute.</p>
        <p><span className="sentence-start">O</span>ffering this page for free and without ads means every form of <a href="https://ko-fi.com/geoblex" target="_blank" rel="noopener noreferrer">support</a> will help</p>
      </div>
    </>
  );
}

export default About;