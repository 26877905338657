import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

function Converter() {
  useEffect(() => {
    document.title = 'AC Blockbench Tool - Geoverse';
  }, []);

  const [file, setFile] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const currentPath = process.env.NODE_ENV === 'production' 
  ? '/adventurecraft/converter' 
  : 'http://localhost:3001';

  console.log('NODE_ENV:', process.env.NODE_ENV);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setDownloadUrl(null);  // Reset download link if a new file is selected
  };

  const handleConvert = async () => {
    if (!file) {
        alert("Please select a file first.");
        return;
    }

    setIsConverting(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${currentPath}/upload`, {
        method: 'POST',
        body: formData,
    });
    
        if (!response.ok) {
            throw new Error(`Server error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Conversion Result:', data);

        if (data.downloadUrl) {
            setDownloadUrl(data.downloadUrl);
        }

        alert('Conversion successful!');
    } catch (error) {
        console.error('Error during conversion:', error);
        alert(`Conversion failed: ${error.message}`);
    } finally {
        setIsConverting(false);
    }
};

  return (
    <div className="container">
      <h1>Blockbench to Adventurecraft</h1>
      <p>Model Converter version 0.8</p>

      <div className="mb-3">
        <input
          type="file"
          className="form-control"
          accept=".bbmodel"
          onChange={handleFileChange}
        />
      </div>

      <button
        className="btn btn-primary"
        onClick={handleConvert}
        disabled={!file || isConverting}
      >
        {isConverting ? 'Converting...' : 'Convert'}
      </button>

      {downloadUrl && (
        <div className="mt-3">
            <a href={`${currentPath}${downloadUrl}`} className="btn btn-success" download>
                Download Converted File
            </a>
        </div>
      )}

      <div className="mt-4">
        <h2>How to Use the Converter</h2>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/GrPeDA7OkT0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Converter;
