// src/Adventurecraft.js
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Adventurecraft.css'; // Import the specific styles for the About page

function Adventurecraft() {
  useEffect(() => {
    document.title = 'AC Start - Geoverse';
  }, []);
  return (
    <>
      <div className="apply-background"></div> {/* Background div */}
    <div className="about-container">
      <h1>About Adventurecraft</h1>
      <p>A mod originally created by Cryect has been revived in the form of Adventurecraft Awakening. <br></br><br></br>Build or play unique Adventuremaps like Zelda Adventure!</p>
      <a href="https://adventurecraft.dev/launcher?q=%2Flauncher" target="_blank" rel="noopener noreferrer">
        Download the Official Launcher here
      </a>
    </div>
    </>
  );
}

export default Adventurecraft;
