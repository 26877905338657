// src/HelloWorld.js
import React from 'react';

function HelloWorld() {
  return (
    <div>
      <h2>Hello React!</h2>
    </div>
  );
}

export default HelloWorld;
