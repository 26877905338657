import React, { useState, useEffect } from 'react';
import CodeExample from './CodeExample';
import UploadButton from './UploadButton';
import './css/Scripts.css';

const exampleCode = `
screen.clear();

var labelText = "Pumpkin Count";
var label = UILabel(labelText, ui.getWidth() - ui.getStringWidth(labelText)-15,20);
var pumpkinSprite = UISprite("/terrain.png",ui.getWidth()-50, 0, 16,16, 112, 112);
var counterLabel = UILabel(countPumpkin(), ui.getWidth() - ui.getStringWidth(labelText)+20,40);
counterLabel.red = 0;
counterLabel.green = 0.5;
counterLabel.blue = 0.5;

var rect = UIRect(ui.getWidth() - ui.getStringWidth(labelText)-15, 30, ui.getStringWidth(labelText), 5, 0, 0, 1, 0.25);

function countPumpkin(){
    var pumpkinCount = 0;
    var pinv = player.getInventory();

    for(let i = 0; i < pinv.getSizeInventory();i++){
        var itemInSlot = pinv.getItemInSlot(i);
        if(itemInSlot && itemInSlot.getItemID() == 86){
            pumpkinCount+= itemInSlot.getQuantity();
        }
    }
    return pumpkinCount;
}
`;

const exampleCode2 = `
var isRaining = weather.getPrecipitating();

if(!isRaining){
    weather.setPrecipitating(true);
    weather.temperatureOffset = 1;
} else weather.setPrecipitating(false);
`;

function Scripts() {
  useEffect(() => {
    document.title = 'AC Scripts - Geoverse';
  }, []);
  const [codeExamples, setCodeExamples] = useState([
    {
      name: "UI Label Test",
      description: "Welp I don't know what to say other than description text",
      version: "1.0.0",
      date: "2024-06-20",
      author: "Geoblex",
      codeString: exampleCode,
      language: "javascript",
    },
    {
      name: "Weather Test",
      description: "Nothing fancy just a Test so stop reading this text!",
      version: "0.0.1",
      date: "2024-06-20",
      author: "Geoblex",
      codeString: exampleCode2,
      language: "javascript",
    },
  ]);

  const handleFileUpload = (fileContent, fileName) => {
    const newCodeExample = {
      name: fileName,
      description: "Uploaded JavaScript file",
      version: "1.0.0",
      date: new Date().toLocaleDateString(),
      author: "Uploaded by User",
      codeString: fileContent,
      language: "javascript",
    };
    setCodeExamples([...codeExamples, newCodeExample]);
  };

  return (
    <div className="scripts-container">
      <h1>Scripts</h1>
      <h2>This is the Scripts page of Adventurecraft.</h2>
      <UploadButton onFileUpload={handleFileUpload} />
      {codeExamples.map((example, index) => (
        <React.Fragment key={index}>
          <CodeExample
            name={example.name}
            description={example.description}
            version={example.version}
            date={example.date}
            author={example.author}
            codeString={example.codeString}
            language={example.language}
          />
          <hr className="separator" />
        </React.Fragment>
      ))}
    </div>
  );
}

export default Scripts;
