import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus, faComment } from '@fortawesome/free-solid-svg-icons';
import LikeDislike from './LikeDislike';
import './css/CodeExample.css';

const CodeExample = ({ name, description, version, date, author, codeString, language }) => {
  const [fontSize, setFontSize] = useState(16);
  const [comments, setComments] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [showCommentForm, setShowCommentForm] = useState(false);

  const handleZoomIn = () => {
    setFontSize((prevFontSize) => prevFontSize + 2);
  };

  const handleZoomOut = () => {
    setFontSize((prevFontSize) => Math.max(8, prevFontSize - 2));
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (commentInput.trim()) {
      setComments([...comments, commentInput.trim()]);
      setCommentInput("");
      setShowCommentForm(false);
    }
  };

  return (
    <div className="code-example-container">
      <div className="metadata-container">
        <div className="metadata-left">
          <span className="code-version">Version: {version}</span>
          <span className="code-date">Date: {date}</span>
        </div>
        <div className="metadata-middle">
          <h2 className="code-name">{name}</h2>
        </div>
        <div className="metadata-right">
          <div className="code-controls">
            <FontAwesomeIcon icon={faSearchPlus} onClick={handleZoomIn} />
            <FontAwesomeIcon icon={faSearchMinus} onClick={handleZoomOut} />
          </div>
          <span className="code-author">Author: {author}</span>
        </div>
      </div>
      <div className="zoomable-code-container">
        <div className="code-wrapper">
          <SyntaxHighlighter language={language} style={atomDark} customStyle={{ fontSize: `${fontSize}px`, margin: 0 }}>
            {codeString}
          </SyntaxHighlighter>
        </div>
      </div>
      <div className="description-container">
        <h3 className="description-label">Description:</h3>
        <p className="code-description">{description}</p>
      </div>
      <LikeDislike />
      <div className="comment-section">
        <button className="comment-button" onClick={() => setShowCommentForm(!showCommentForm)}>
          <FontAwesomeIcon icon={faComment} /> Comment
        </button>
        {showCommentForm && (
          <form className="comment-form" onSubmit={handleCommentSubmit}>
            <textarea
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
              placeholder="Write a comment..."
            />
            <button type="submit">Post Comment</button>
          </form>
        )}
        <div className="comments-container">
          {comments.map((comment, index) => (
            <p key={index} className="comment">{comment}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CodeExample;
